import React, { useState, useEffect } from "react";
import * as ymaps from "ymaps";
import "./YandexMapApi.css";

const YandexMapApi = ({ isPopupOpen }) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([24.10607528686523, 56.94683837890625]); // Координаты центра карты
  const [zoom, setZoom] = useState(15);
  const [multiRoute, setMultiRoute] = useState(null); // Уровень масштабирования

  useEffect(() => {
    const initializeMap = () => {
      ymaps.default
        .load(
          "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=2636e3d7-04df-4dd4-b10e-8076fc792cce"
        )
        .then((res) => {
          res.geolocation
            .get({
              provider: "yandex",
            })
            .then((coords) => {
              let myPos = coords.geoObjects.get(0).properties.get("text");

              if (
                !isNaN(coords.geoObjects.position[0]) &&
                !isNaN(coords.geoObjects.position[1])
              ) {
                setCenter(myPos);

                console.log(
                  `My position: ${coords.geoObjects.position[0]}, ${coords.geoObjects.position[1]}`
                );
              } else {
                console.error("Error, cant take position");
              }
            });
          console.log(center);
        });
    };

    initializeMap();
  }, []);

  useEffect(() => {
    console.log(center, 44);

    ymaps.default
      .load(
        "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=2636e3d7-04df-4dd4-b10e-8076fc792cce"
      )
      .then((res) => {
        const myMap = new res.Map(
          "map",
          {
            center,
            zoom,
          },
          {
            searchControlProvider: "yandex#search",
          }
        );

        setMap(myMap);
      });
  }, [center]);

  return (
    <>
      <button
        onClick={() => {
          // console.log(map);

          // let control = map.controls.get("routePanelControl");
          // console.log(33, control);

          // control.routePanel.state.set({
          //   type: "masstrasit",
          //   fromEnabled: false,
          //   from: center,
          //   toEnabled: true,
          //   to: "Ивантеевка",
          // });
          // control.routePanel.option.set({
          //   types: {
          //     masstransit: true,
          //     pedestrian: true,
          //     taxi: true,
          //   },
          // });
          ymaps.default
            .load(
              "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=2636e3d7-04df-4dd4-b10e-8076fc792cce"
            )
            .then((res) => {
              const currentRoute = new res.multiRouter.MultiRoute(
                {
                  referencePoints: [center, "Москва, метро Арбатская"],
                  params: {
                    // Тип маршрута: на общественном транспорте.
                    routingMode: "masstransit",
                  },
                },
                {
                  // Автоматически устанавливать границы карты так,
                  // чтобы маршрут был виден целиком.
                  boundsAutoApply: true,
                }
              );
              map.geoObjects.add(currentRoute);
            });
          console.log(map, center);
        }}
      ></button>
      <div className="yandex-map" id="map"></div>
    </>
  );
};

export default YandexMapApi;
